import type { AddCartItemProps, DefinitiveOrderForm } from '~/types/application'
import type { Clientorder, ClientorderItem, Discount } from '~/types/demegro'
import { defineStore } from 'pinia'

export const useCartStore = defineStore('cartStore', () => {
    const authStore = useAuthStore()

    // State
    const clientorder = ref<Clientorder | null>(null)
    const items = ref<ClientorderItem[]>([])
    const discounts = ref<Discount[]>([])
    const authenticated = computed(() => authStore.authenticated)
    const orderComment = computed(() => clientorder.value?.comment)

    // Computed
    const currentDiscount = computed(() => clientorder.value?.discount)
    const totalItems = computed(() => items.value.reduce((total, item) => total + item.amount, 0))
    const totalWithoutDiscountPrice = computed(() => items.value.reduce((total, item) => total + (item.price || 0) * item.amount, 0))
    const totalPrice = computed(() => totalWithoutDiscountPrice.value - (currentDiscount.value?.discount ?? 0))
    const itemsCount = computed(() => items.value.length)
    const isEmpty = computed(() => items.value.length === 0)
    const totalAmount = computed((): number => {
        if (!items.value?.length)
            return 0
        return items.value.reduce((total, item) => total + (Number(item.amount) || 0), 0)
    })

    const mergeEditingItems = (response: Clientorder): Clientorder => {
        if (!items.value?.length)
            return response

        const editingItems = items.value.filter(item => item.editing)
        if (!editingItems.length)
            return response

        return {
            ...response,
            items: response.items.map((newItem) => {
                const editingItem = editingItems.find(item => item.id === newItem.id)
                return editingItem || newItem
            }),
        }
    }

    // Actions
    const setCart = (cart: Clientorder | null) => {
        clientorder.value = cart
        items.value = cart?.items || []
    }

    // Get discount
    const { status: discountStatus, refresh: refreshDiscount } = useAsyncData(
        'discount',
        async () => {
            if (!authenticated.value)
                return null

            const discount = await $fetch('/api/discount', {
                method: 'GET',
            })

            if (!discount)
                return null

            discounts.value = discount
        },
        {
            watch: [authenticated],
            immediate: true,
            server: false, // We need to wait for client to process authentication
        },
    )

    const discountLoading = computed(() => discountStatus.value === 'pending')

    // Get cart
    const { status: cartStatus, refresh } = useAsyncData(
        'cart',
        async () => {
            if (!authenticated.value) {
                setCart(null)
                return null
            }

            const cart = await $fetch('/api/carts')

            if (!cart) {
                clientorder.value = null
                items.value = []
                return
            }

            const mergedCart = mergeEditingItems(cart)

            setCart(mergedCart)
        },
        {
            watch: [authenticated],
            immediate: true,
            server: false, // We need to wait for client to process authentication
        },
    )

    const loading = computed(() => status.value === 'pending')

    const setItemEditing = (itemId: number, isEditing: boolean) => {
        items.value = items.value.map(item => (item.id === itemId ? { ...item, editing: isEditing } : item))
    }

    const addItem = async (cartItem: AddCartItemProps) => {
        await $fetch<Clientorder>('/api/carts', {
            method: 'POST',
            body: cartItem,
        })
    }

    const updateItemAmount = async (cartItem: AddCartItemProps) => {
        await $fetch(`/api/carts/${cartItem.clientorder_id}`, {
            method: 'PUT',
            body: cartItem,
        })
    }

    const removeItem = async (item_id: number) => {
        await $fetch<Clientorder>('/api/carts', {
            method: 'DELETE',
            body: {
                item_id,
            },
        })
    }

    const complete = async (order: DefinitiveOrderForm) => {
        await $fetch('/api/carts/complete', {
            method: 'POST',
            body: order,
        })

        refreshDiscount()
        clear()
        refresh()
    }

    const applyDiscount = async (code: string) => {
        await $fetch('/api/discount', {
            method: 'PUT',
            body: {
                order_id: clientorder.value?.id,
                code,
            },
        })
    }

    const removeDiscount = async (code: string) => {
        await $fetch('/api/discount', {
            method: 'DELETE',
            body: {
                order_id: clientorder.value?.id,
                code,
            },
        })
    }

    function clear() {
        clientorder.value = null
        items.value = []
        discounts.value = []
    }

    function updateOrderComment(comment?: string | null) {
        if (!clientorder.value?.id)
            return

        $fetch(`/api/orders/${clientorder.value.id}`, {
            method: 'PUT',
            body: {
                comment,
            },
        })
    }

    return {
        // State
        clientorder: computed(() => clientorder.value),
        items: computed(() => items.value),
        loading,
        discounts: computed(() => discounts.value),
        currentDiscount: computed(() => currentDiscount.value),
        orderComment,
        // Computed
        totalItems,
        totalPrice,
        itemsCount,
        isEmpty,
        totalAmount,
        discountLoading,
        // Actions
        refresh,
        refreshDiscount,
        addItem,
        updateItemAmount,
        removeItem,
        setItemEditing,
        complete,
        applyDiscount,
        removeDiscount,
        clear,
        updateOrderComment,
        cartStatus,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot))
}
