
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as alle_45bestellingenCwt5m6iJ0IMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/account/alle-bestellingen.vue?macro=true";
import { default as facturenOwv2D59GEEMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/account/facturen.vue?macro=true";
import { default as indexK4CDP9u9qTMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/account/index.vue?macro=true";
import { default as instellingenA4eRI3uVHJMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/account/instellingen.vue?macro=true";
import { default as openstaande_45bestellingenbfuo6yGiADMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/account/openstaande-bestellingen.vue?macro=true";
import { default as accountckJwiH82Q6Meta } from "/builds/brixion/demegro/demegro-frontend/app/pages/account.vue?macro=true";
import { default as algemene_45voorwaardenlDhldxO1zVMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/algemene-voorwaarden.vue?macro=true";
import { default as _91_46_46_46slug_93VaUg5qtC1rMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/[chapter]/[category]/[manufacturer]/[article]/[...slug].vue?macro=true";
import { default as index_46clientOMQZ0cOEpQMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/[chapter]/[category]/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/brixion/demegro/demegro-frontend/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as index3H33NZBlcWMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/[chapter]/index.vue?macro=true";
import { default as index32CarWu2wQMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/index.vue?macro=true";
import { default as kort_45houdbaar1EEUGtlvzkMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/kort-houdbaar.vue?macro=true";
import { default as nieuwXo96FbswbFMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/nieuw.vue?macro=true";
import { default as voorkeursartikelen_46clientrE57l5prvdMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/voorkeursartikelen.client.vue?macro=true";
import { default as contact5yj4LRr8kRMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/contact.vue?macro=true";
import { default as indexXKdH4PRBHtMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/index.vue?macro=true";
import { default as over_45onsXiVJp2Ujn0Meta } from "/builds/brixion/demegro/demegro-frontend/app/pages/over-ons.vue?macro=true";
import { default as reparatiekirFAB3gYYMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/reparatie.vue?macro=true";
import { default as _91job_93QzlRTSZJkAMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/vacatures/[job].vue?macro=true";
import { default as indexr8D8sY52H3Meta } from "/builds/brixion/demegro/demegro-frontend/app/pages/vacatures/index.vue?macro=true";
import { default as veelgestelde_45vragengSdCOebwj4Meta } from "/builds/brixion/demegro/demegro-frontend/app/pages/veelgestelde-vragen.vue?macro=true";
import { default as winkelwagen_45compleetjsG8cOxLJwMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/winkelwagen-compleet.vue?macro=true";
import { default as winkelwagenhDkT4igzetMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/winkelwagen.vue?macro=true";
import { default as _91type_93_46clientO03VuLnrZsMeta } from "/builds/brixion/demegro/demegro-frontend/app/pages/zoeken/[type].client.vue?macro=true";
export default [
  {
    name: accountckJwiH82Q6Meta?.name,
    path: "/account",
    meta: accountckJwiH82Q6Meta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/account.vue"),
    children: [
  {
    name: "account-alle-bestellingen",
    path: "alle-bestellingen",
    meta: alle_45bestellingenCwt5m6iJ0IMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/account/alle-bestellingen.vue")
  },
  {
    name: "account-facturen",
    path: "facturen",
    meta: facturenOwv2D59GEEMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/account/facturen.vue")
  },
  {
    name: "account",
    path: "",
    meta: indexK4CDP9u9qTMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/account/index.vue")
  },
  {
    name: "account-instellingen",
    path: "instellingen",
    meta: instellingenA4eRI3uVHJMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/account/instellingen.vue")
  },
  {
    name: "account-openstaande-bestellingen",
    path: "openstaande-bestellingen",
    meta: openstaande_45bestellingenbfuo6yGiADMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/account/openstaande-bestellingen.vue")
  }
]
  },
  {
    name: "algemene-voorwaarden",
    path: "/algemene-voorwaarden",
    meta: algemene_45voorwaardenlDhldxO1zVMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/algemene-voorwaarden.vue")
  },
  {
    name: "assortiment-chapter-category-manufacturer-article-slug",
    path: "/assortiment/:chapter()/:category()/:manufacturer()/:article()/:slug(.*)*",
    meta: _91_46_46_46slug_93VaUg5qtC1rMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/[chapter]/[category]/[manufacturer]/[article]/[...slug].vue")
  },
  {
    name: "assortiment-chapter-category",
    path: "/assortiment/:chapter()/:category()",
    meta: index_46clientOMQZ0cOEpQMeta || {},
    component: () => createClientPage(() => import("/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/[chapter]/[category]/index.client.vue"))
  },
  {
    name: "assortiment-chapter",
    path: "/assortiment/:chapter()",
    meta: index3H33NZBlcWMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/[chapter]/index.vue")
  },
  {
    name: "assortiment",
    path: "/assortiment",
    meta: index32CarWu2wQMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/index.vue")
  },
  {
    name: "assortiment-kort-houdbaar",
    path: "/assortiment/kort-houdbaar",
    meta: kort_45houdbaar1EEUGtlvzkMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/kort-houdbaar.vue")
  },
  {
    name: "assortiment-nieuw",
    path: "/assortiment/nieuw",
    meta: nieuwXo96FbswbFMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/nieuw.vue")
  },
  {
    name: "assortiment-voorkeursartikelen",
    path: "/assortiment/voorkeursartikelen",
    meta: voorkeursartikelen_46clientrE57l5prvdMeta || {},
    component: () => createClientPage(() => import("/builds/brixion/demegro/demegro-frontend/app/pages/assortiment/voorkeursartikelen.client.vue"))
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/index.vue")
  },
  {
    name: "over-ons",
    path: "/over-ons",
    meta: over_45onsXiVJp2Ujn0Meta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/over-ons.vue")
  },
  {
    name: "reparatie",
    path: "/reparatie",
    meta: reparatiekirFAB3gYYMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/reparatie.vue")
  },
  {
    name: "vacatures-job",
    path: "/vacatures/:job()",
    meta: _91job_93QzlRTSZJkAMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/vacatures/[job].vue")
  },
  {
    name: "vacatures",
    path: "/vacatures",
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/vacatures/index.vue")
  },
  {
    name: "veelgestelde-vragen",
    path: "/veelgestelde-vragen",
    meta: veelgestelde_45vragengSdCOebwj4Meta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/veelgestelde-vragen.vue")
  },
  {
    name: "winkelwagen-compleet",
    path: "/winkelwagen-compleet",
    meta: winkelwagen_45compleetjsG8cOxLJwMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/winkelwagen-compleet.vue")
  },
  {
    name: "winkelwagen",
    path: "/winkelwagen",
    meta: winkelwagenhDkT4igzetMeta || {},
    component: () => import("/builds/brixion/demegro/demegro-frontend/app/pages/winkelwagen.vue")
  },
  {
    name: "zoeken-type",
    path: "/zoeken/:type()",
    meta: _91type_93_46clientO03VuLnrZsMeta || {},
    component: () => createClientPage(() => import("/builds/brixion/demegro/demegro-frontend/app/pages/zoeken/[type].client.vue"))
  }
]