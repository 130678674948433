import type { Article } from '~/types/demegro'
import { defineStore } from 'pinia'

export const useFavoritesStore = defineStore('favoritesStore', () => {
    // State
    const currentlyToggling = ref<Set<number>>(new Set())
    const favorites = ref<Article[]>([])

    // Dependencies
    const authenticated = toRef(useAuthStore(), 'authenticated')

    // API Integration
    const { refresh, status, error } = useAsyncData(
        'favorites',
        async () => {
            if (!authenticated.value) {
                favorites.value = []
                return
            }

            const response = await $fetch('/api/accounts/favorites/list')

            if (response) {
                favorites.value = response.articles
            }
        },
        { watch: [authenticated], immediate: false },
    )

    watchEffect(() => {
        // If we have never fetched favorites, fetch them
        // We do not always fetch favorites on store load because we will get duplicate requests
        if (status.value === 'idle' && !favorites.value.length) {
            refresh()
        }
    })

    // Computed
    const hasFavorites = computed(() => (favorites.value?.length || 0) > 0)
    const loading = computed(() => status.value === 'pending')

    async function toggle(article: Article, add: boolean) {
        if (!authenticated.value || currentlyToggling.value.has(article.id))
            return

        currentlyToggling.value.add(article.id)

        const endpoint = add ? '/api/accounts/favorites/add' : '/api/accounts/favorites/delete'
        const response = await $fetch<{ success: boolean, articles: Article[] }>(endpoint, {
            method: 'POST',
            body: { article_id: article.id },
            onResponseError() {
                currentlyToggling.value.delete(article.id)
            },
        })

        if (response.success) {
            article.is_favorite = add

            if (add) {
                favorites.value?.push({
                    ...article,
                    is_favorite: true,
                })
            } else {
                favorites.value = favorites.value?.filter(fav => fav.id !== article.id)
            }
        }
        currentlyToggling.value.delete(article.id)

        return response.success
    }

    function articleIsFavorite(article: Article) {
        return favorites.value?.some(fav => fav.id === article.id)
    }

    function clear() {
        favorites.value = []
        currentlyToggling.value = new Set()
    }

    return {
        // State
        favorites,
        loading,
        error,
        // Computed
        hasFavorites,
        // Actions
        toggle,
        refresh,
        articleIsFavorite,
        clear,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useFavoritesStore, import.meta.hot))
}
